@import 'reset';
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);

$darker-blue: #262261;

*, *:before, *:after
{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body{
	font-family: 'Open Sans', sans-serif;
	background: #eee;
}
.container{
	max-width: 1024px;
	margin: 0 auto;
}

main{
	.container{
		background: #fff;
	}
}
header{
	background: #00a3e4;
	.brand{
		margin: 0 auto;
		width: 160px;
		img{
			width: 100%;
			padding: 15px 0;
		}
	}
	nav{
		background: #0069aa;
		text-align: center;
		.container{
			height: 50px;
			li{
				display: inline;
				list-style: none;
				text-align: center;
				position: relative;
				a{
					display: inline-block;
					text-decoration: none;
					color: #fff;
					font-size: 20px;
					font-weight: 600;
					padding: 15px 50px;
					margin-right: -4px;
					&:active, &:hover, &.active{
						background: #00a3e4;
					}
				}
				ul{
					top: 37px;
					left: -9999px;
					position: absolute;
					list-style: none;
					z-index: 9999;
					width: 100%;
					li{
						display: block;
						width: 100%;
						background: #0069aa;
						a{

							font-size: 16px;
							font-weight: 400;
							width: 100%;
							margin-left: -4px;
							padding: 8px 15px;
						}
					}
				}
				&:hover ul{
					left: 0;
				}
			}
		}
	}
	.container.lang{
		position: relative;
		ul{
			position: absolute;
			right: 0;
			top: 0;
			li{
				display: inline-block;
				list-style: none;
				a{
					text-decoration: none;
					color: #000;
					font-weight: 600;
					border-bottom: 1px solid transparent;
				}
				&:active, &:hover, &.active{
					a{
						border-bottom: 2px solid #fff;
					}
				}
				&:first-child{
					margin-right: 8px;
				}
			}
		}
		.cards{
			color: #fff;
			text-align: right;
			position: absolute;
			bottom: 0;
			right: 0;
			font-size: 14px;
			span{
				display: block;
				&:nth-child(2){
					vertical-align: bottom;
					margin-bottom: 5px;
				}
			}
			a{
				text-decoration: none;
				color: #0000ED;
			}
		}
	}
}
#fader{
	opacity: 0;
}
main{

	h1{
		font-size: 38px;
		font-weight: 400;
		text-align: center;
		color: $darker-blue;
		padding-top: 15px;
	}
	h2{
		font-size: 25px;
		font-weight: 400;
		text-align: center;
		color: $darker-blue + 30;
		margin-bottom: 15px;
	}
	p{
		max-width: 900px;
		margin: 0 auto;
		
		text-align: justify;
		font-size: 16px;
		letter-spacing: 0.7px;
		line-height: 20px;
		padding-bottom: 20px;
		span{
			color: $darker-blue;
			vertical-align: top;
			font-weight: 600;
		}
	}
	ul{
		font-size: 16px;
		li{
			margin-bottom: 5px;
			&:before{
				content: "\2022";
				padding-right: 4px;
				color: $darker-blue;
				font-size: 20px;
			}
		}
	}
}
#home{
	#slideshow{
		img{
			width: 100%;
		}
	}
	h1{
		margin: 10px 0;
	}
	article{
		width: 70%;
		float: left;
		padding: 0 40px;
	}
	
	.hours{
        padding: 5px;
        width: 30%;
        float: left;
        table{
        	border: 3px solid #0069aa;
        	h3{
				text-align: center;
			}
        	td{
        		padding: 5px 10px;
        		line-height: 20px;
        		&:first-child{
        			font-weight: 600;
        		}
        	}
        }
	}
	
}

#about{

	ul{
		max-width: 800px;
		margin: 0  auto;
	}
	img{
		width: 100%;
	}
}

#services{
	img{
		width: 100%;
	}
	ul{
		width: 50%;
		float: left;
		padding-bottom: 15px;
		padding-left: 30px;
		&:first-of-type{
			padding-left: 150px;
		}
		a{
			color: $darker-blue;
		}
	}
}

#service{
	h1{
		margin-bottom: 6px;
	}
	ul{
		max-width: 800px;
		margin: 0 auto;
	}
	img{
		display: block;
		max-width: 70%;
		margin: 0 auto;
	}
	p{
		padding-bottom: 10px;
	}
	.container{
		padding-bottom: 20px;
	}
}
footer{
	background: #0069aa;
	.container{
		overflow: hidden;
		position:relative;
	}

	.contact{
		width: 25%;
		float: left;
		padding: 20px 0;
		
		div{
			i{
				width: 20px;
				text-align: center;
			}
			font-weight: 600;
			span{
				display: block;
				word-wrap: break-word;
				font-weight: 400;
				line-height: 22px;
			}
			margin-bottom: 8px;
		}
	}
	h3{
		font-size: 19px;
		font-weight: 600;
		margin-bottom: 8px;
	}
	form{
		padding: 20px 15px;
		width: 35%;
		float: left;
		input, textarea{
			width: 100%;
			padding: 0;
			border: none;
			font-size: 16px;
			font-weight: 300;
			padding: 8px 5px;
			margin-bottom: 3px;
			&[type="submit"]{
				background: $darker-blue;
				color: #fff;
				font-weight: 700;
				cursor: pointer;
			}
		}
	}
	#map-canvas{
		width: 40%;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
	}
}

@media (max-width: 760px){
	header{
		nav{
			.container{
				height: 34px;
				li{
					a{
						font-size: 14px;
						font-weight: 400;
						padding: 10px 12px;
						margin-right: -4px;
					}
					ul{
						display: none;
					}
				}
			}
		}
		.container.lang{
			margin-right: 5px;
			.cards{
				position: relative;
			}
		}
	}

	#home{
		h1{
			font-size: 20px;
		}
		article{
			width: 100%;
			float: none;
			padding: 0 40px;
		}
		
		.hours{
	        padding: 5px;
	        width: 100%;
	        float: none;
	        table{
	        	margin: 0 auto;
	        	border: 3px solid #0069aa;
	        	h3{
					text-align: center;
				}
	        	td{
	        		padding: 5px 10px;
	        		line-height: 20px;
	        		&:first-child{
	        			font-weight: 600;
	        		}
	        	}
	        }
		}
	
	}

	main{
		p{
			padding: 0 10px 20px 10px;
		}
	}

	#services{
		ul{
			width: 50%;
			float: left;
			padding-bottom: 15px;
			padding-left: 30px;
			&:first-of-type{
				padding-left: 30px;
			}
		}
	}

	footer{

		.contact{
			width: 100%;
			float: none;
			padding: 20px 15px 0 15px;
		}
		h3{
			font-size: 19px;
			font-weight: 600;
			margin-bottom: 8px;
		}
		form{
			padding: 0 15px 20px 15px;
			width: 100%;
			float: none;
		}
		#map-canvas{
			width: 100%;
			position: relative;
			height: 400px;
		}
	}
}